<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" :value="null" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group class="w-52">
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="getData">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="AP_AGING"/>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <table class="table-fixed table-sticky border-collapse w-full">
            <colgroup>
              <col class="w-10"/>
              <col class="w-52"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
              <col class="w-32"/>
            </colgroup>
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th colspan="2" class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Description</th>
                <th colspan="4" class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Transaction</th>
                <th colspan="6" class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Aging Period Per Today - {{ currentDate }}</th>
              </tr>
              <tr class="d-theme-dark-bg">
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">#</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Nama Rekanan</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Open Balance</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Additional</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Paid</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Last Balance</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Not in Due</th> <!--hutang yang belum jatuh tempo, dihitung mulai dari now-->
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">0-30 Days</th> <!--hutang yang sudah jatuh tempo pada 30 hari kebelakang, dihitung mulai dari now-->
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">31-60 Days</th> <!--hutang yang sudah jatuh tempo pada 60 hari kebelakang, dihitung mulai dari now-->
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">61-90 Days</th> <!--hutang yang sudah jatuh tempo pada 90 hari kebelakang, dihitung mulai dari now-->
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">>90 Days</th> <!--hutang yang sudah jatuh tempo pada lebih dari 90 hari kebelakang, dihitung mulai dari now-->
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Total</th>
              </tr>
            </thead>

            <!-----------TABLE BODY------------>
            <tbody>
              <template>
                <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover-bg-primary-transparent-10" @dblclick="onItemDoubleClicked(item)">
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ index + 1 }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama_person }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_open_balance | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_additional | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_paid | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_last_balance | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_not_in_due | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_less_30 | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_in_31_60 | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_in_61_90 | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_more_90 | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total | idr }}</td>
                </tr>
              </template>
            </tbody>

            <!-----------TABLE FOOT------------>
            <tfoot v-if="data.items.length > 0">
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotalOpenBalance | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotalAdditional | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotalPaid | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotalLastBalance | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotalNotInDue | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotalLess30 | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotal3160 | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotal6190 | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotal90More | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ footer.grandTotal | idr }}</th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2">
          <div class="h-8">
            <!--Button Goes Here-->
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <ApAgingDetail :isActive.sync="modalDetail.active" :params="modalDetail.params"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ApAgingRepository from '@/repositories/accounting/ap-aging-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'TabApAging',
  props: ['isActive'],
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    ApAgingDetail: () => import('@/views/pages/accounting/account-payable/ap-aging/part/ApAgingDetail'),
    flatPickr
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    footer () {
      return {
        grandTotalOpenBalance: _.sumBy(this.data.items, item => parseFloat(item.total_open_balance)),
        grandTotalAdditional: _.sumBy(this.data.items, item => parseFloat(item.total_additional)),
        grandTotalPaid: _.sumBy(this.data.items, item => parseFloat(item.total_paid)),
        grandTotalLastBalance: _.sumBy(this.data.items, item => parseFloat(item.total_last_balance)),
        grandTotalNotInDue: _.sumBy(this.data.items, item => parseFloat(item.total_not_in_due)),
        grandTotalLess30: _.sumBy(this.data.items, item => parseFloat(item.total_less_30)),
        grandTotal3160: _.sumBy(this.data.items, item => parseFloat(item.total_in_31_60)),
        grandTotal6190: _.sumBy(this.data.items, item => parseFloat(item.total_in_61_90)),
        grandTotal90More: _.sumBy(this.data.items, item => parseFloat(item.total_more_90)),
        grandTotal: _.sumBy(this.data.items, item => parseFloat(item.total))
      }
    },
    currentDate () {
      return moment().format('DD MMMM YYYY')
    }
  },
  data () {
    return {
      isDataInited: false,
      loading: {
        getData: false
      },
      modalDetail: {
        params: {},
        active: false
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD')
      },
      data: {
        items: []
      },
      proyeks: []
    }
  },
  methods: {
    initData () {
      this.getProyek()
      this.getData()
      this.isDataInited = true
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.getData = true

      ApAgingRepository.getApAgingList(this.filter)
        .then(response => {
          this.data.items = _.cloneDeep(response.data.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    onItemDoubleClicked (item) {
      this.modalDetail.params = {
        tipe_person: item.tipe_person,
        id_person: item.id_person,
        nama_person: item.nama_person,
        id_proyek: this.filter.id_proyek,
        nama_proyek: this.filter.id_proyek ? _.find(this.proyeks, proyek => proyek.id === this.filter.id_proyek).nama : 'Semua',
        periode_akhir: this.filter.periode_akhir
      }
      this.modalDetail.active = true
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
